<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat>
      <v-icon left>mdi-account-box-outline</v-icon>
      <v-toolbar-title>PLANTILLAS DE MENSAJE</v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>
      <v-btn 
        @click="nuevaPlantilla(null)" 
        color="blue" 
        small 
        dark
      >
        <v-icon>mdi-plus</v-icon> Crear
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items-per-page="itemsPerPage"
      :items="plantillas"
      class="elevation-1"
      small
      item-key="message_template_id"
      
      hide-default-footer
    >
      <template v-slot:item.opciones="{ item }">
        
        <v-btn
          small
          text
          @click="borrarPlantilla(item.message_template_id)"
          color="blue"
          dark
          ><v-icon small color="red">mdi-delete</v-icon></v-btn
        >
        <v-btn icon small color="green" @click="editarPlantilla(item)">
          <v-icon small color green>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table>

    <!-- DIALOGO PLANTILLA -->
    <v-dialog v-model="dialogoPlantilla" persistent width="700">
      <v-form ref="formPlantilla">
        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Plantilla
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoPlantilla = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="4" class="py-0 my-0">
              
              <v-select                  
                
                v-model="datosPlantilla.modulo"
                label="Módulo"
                :items="modulos"
                item-text="nombre"
                :rules="requiredRule"
              />
            </v-col>

            <v-col md="4" class="py-0 my-0">
              <v-select                  
                v-model="datosPlantilla.tipo_mensaje_id"
                label="Tipo de plantilla"
                :items="tiposPlantilla"
                item-text="nombre"
                item-value="tipo_mensaje_id"
                :rules="requiredRule"
              />
            </v-col>
          </v-row>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" >
              <b>Campos disponibles:</b>
            </v-col>
            <v-col md="12" class="py-0 my-0">
              <v-card elevation="1" class="mt-0 mb-3">
                  <span 
                    dense
                    class="mx-2"
                    v-for="(camp, x) in modulos.find(e => e.nombre == datosPlantilla.modulo)?.campos"
                    :key="x"
                  >
                    {{ camp }}
                  </span>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
              <v-text-field
                :rules="requiredRule"
                v-model="datosPlantilla.nombre"
                label="Nombre"
              /> 
            </v-col>

            <!-- <v-col md="1" class="py-3 my-0">
              
              <v-btn icon title="Ver imagen" @click="descargarImagen()"><v-icon>mdi-eye</v-icon></v-btn>
            </v-col> -->
            <v-col md="12" class="py-0 my-0">
              
              <v-file-input v-if="mostrarSegunTipo()"
                @change="subirImagen()"
                v-model="datosPlantilla.url_archivo"
                label="Cargar archivo"
              ></v-file-input>
            </v-col>            
            
          </v-row>
          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col :md="mdtexto" class="py-0 my-0">
              <v-textarea
                :rules="requiredRule"
                v-model="datosPlantilla.texto"
                :label="textoMensaje"
              ></v-textarea>
            </v-col>

            <v-col md="6" v-if="datosPlantilla.tipo_mensaje_id=='IMAGE'" class="pb-4 mb-4">
              <v-img class="pb-4"
              :aspect-ratio="16/9"
              :src="datosImagen"
              ></v-img>
            </v-col>

            <v-col md="6" v-if="datosPlantilla.tipo_mensaje_id=='VIDEO'" class="pb-2 mb-2">
              <video  controls id="videoprev" :src="datosImagen" width="300px">
                
              </video>
            </v-col>
          </v-row>

          <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="6" class="py-0 my-0">
              <v-text-field
                type="date"
                readonly
                v-model="datosPlantilla.desde"
                label="Desde"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="py-0 my-0">
              <v-text-field
                type="date"
                :rules="requiredRule"
                v-model="datosPlantilla.hasta"
                label="Hasta"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-toolbar class="pt-2 pb-2" flat>
            <v-btn class="mr-4" color="primary" small @click="guardarPlantilla">
              <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>

            <v-btn class="mx-4" text small @click="dialogoPlantilla = false">
              <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOGO PLANTILLA -->
    <v-dialog v-model="dialogoImagen" persistent :width="dialogWidth">

        <v-card>
          <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Imagen
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoImagen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-img
          
          :aspect-ratio="16/9"
          
          :src="datosImagen"
        ></v-img>
        </v-card>
    
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";

export default {
  name: "FuentesLeads",
  data: () => ({
    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 10,
    pageCount: 0,
    required: [(v) => !!v || "El campo es requerido"],
    headers: [
      { text: "Opciones", align: "start", value: "opciones", width: 120 },
      { text: "ID", align: "start", value: "message_template_id" },
      { text: "Nombre", align: "start", value: "nombre" },
      { text: "Texto", align: "start", value: "texto" },
      { text: "Desde", align: "start", value: "desde" },
    ],

    dialogoPlantilla: false,
    datosPlantilla: {
      message_template_id: "",
      nombre: "",
      texto: "",
      desde: "",
      hasta: "",
      modulo:""
    },
    crearPlantilla: false,
    plantillas: [],
    modulos: [], 
    campos: [
      {
        modulo: "Evento",
        campos: ["work_effort_id","work_effort_type_id", "current_status_id",
      "work_effort_name",  "servicio_crm_id", "created_date" , "created_by_user_login",
       "estimated_start_date", "fecha_hora_completa" , "HORA_CITA"]
      }
    ],
    tiposPlantilla: [
        {'tipo_mensaje_id' : 'TEXT', 'nombre' : 'TEXTO' },
        {'tipo_mensaje_id' : 'IMAGE', 'nombre' : 'IMAGE' },
        {'tipo_mensaje_id' : 'VIDEO', 'nombre' : 'VIDEO' },
        // {'tipo_mensaje_id' : 'AUDIO', 'nombre' : 'AUDIO' },        
        // {'tipo_mensaje_id' : 'CONTACT', 'nombre' : 'CONTACTO' },
        // {'tipo_mensaje_id' : 'GEOPOINT', 'nombre' : 'UBICACION' }
     ],
    dialogoImagen: false,
    dialogWidth: 400,
    datosImagen: "",    
    textoMensaje: "Texto",
    mdtexto:6,
    overlay:false

  }),
  computed: {    
    ...mapState("master", ["loadingBtn", "companies","token", "tenantId", "user", "url" ]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),    
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    mostrarSegunTipo() {
      if(this.datosPlantilla.tipo_mensaje_id != "TEXT") {
        return true
      }

      return false
    },

    //cargar datos de la plantilla
    cargarDatos() {
      this.setLoadingTable(true);

      this.setUrl("message-template");

      this.requestApi({
        method: "GET",
        data: {
          page: this.currentPage,
          page_size: this.itemsPerPage,
          paginator: true,
          typeList: 'getTemplate'
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.plantillas = res.data._embedded.message_template;
          this.pageCount = res.data.page_count;
          this.currentPage = res.data.page;
          this.totalItems = res.data.total_items;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    //nueva plantilla
    async nuevaPlantilla(plantillaId) {

      this.modulos = await this.getModulos()
      this.dialogoPlantilla = true;
      
      this.datosPlantilla = {
        message_template_id: "",
        tipo_mensaje_id: "TEXT",
        nombre: "",
        texto: "",
        desde: new Date().toISOString().substring(0,10),
        hasta: "",
        modulo: "",
      };
      this.datosImagen=false;
      this.crearPlantilla = true;
      // if (plantillaId != null) {
      //   this.crearPlantilla = false;
      //   this.cargarDatos(plantillaId);
      // }
    },
    //abre el dialogo para editar la plantilla
    async editarPlantilla(item) {
        this.modulos = await this.getModulos()
        this.dialogoPlantilla = true;
        this.crearPlantilla = false;
        if(['IMAGE','VIDEO'].includes(item.tipo_mensaje_id ) == true) {
          this.textoMensaje = "Caption"
          this.mdtexto= 6
        }
        if(item.tipo_mensaje_id == "TEXT") {
          this.textoMensaje = "Texto"
          this.mdtexto=12

        }
        this.datosPlantilla = {
          message_template_id: item.message_template_id,
          nombre: item.nombre,
          texto: item.texto,
          modulo: item.modulo,
          tipo_mensaje_id: item.tipo_mensaje_id,
          url_archivo: item.data_resource_name,
          desde: item.desde.substring(0,10),
          hasta: item.hasta != null ? item.hasta.substring(0,10) : '',
          content_id: item.content_id
        };
        this.descargarImagen()
        this.crearPlantilla = false;      
    },

    guardarPlantilla() {

      if (!this.$refs.formPlantilla.validate()) {
        return false;
      }
      this.overlay = true
      let formData = new FormData();
      formData.append("accion", this.crearPlantilla == true ? "crearTemplate" : "modificarTemplate");
      formData.append("plantilla", JSON.stringify(this.datosPlantilla));
      formData.append("tenantId", this.tenantId);
      formData.append("archivos[]", this.datosPlantilla.url_archivo);

      this.setUrl("message-template");
      axios.post(this.url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + this.token,
            },
          }).then((res) => {          
              
              this.cargarDatos();
              this.dialogoPlantilla = false;
              this.alertNotification({
                  param: {
                      html: res.data.detail.mesg,
                  },
              });
              this.overlay = false;
          }).catch((err) => {              
              this.overlay = false;
          }).finally((err) => {              
              this.overlay = false;
          });      
    },

    borrarPlantilla(plantillaId) {
      Vue.swal({
        html: "Está seguro de eliminar esta plantilla ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setLoadingTable(true);
          this.setUrl("message-template");
          this.requestApi({
            method: "DELETE",
            data: {
              accion: "borrarPlantilla",
              message_template_id: plantillaId,
            },
          })
            .then((res) => {
              this.cargarDatos();
              this.dialogoPlantilla = false;
            })
            .then(() => {
              this.setLoadingTable(false);
            });
        }
      });
    },

    handlePageChange() {
      this.cargarDatos();
    },

    getModulos(){

      return new Promise((resolve, reject)=>{

        this.setUrl("message-template");

        this.requestApi({
          method: "GET",
          data: {
            typeList: 'getModulos'
          },
        })
        .then(res => {
            console.log('res',res)
          resolve(res.data.detail)

        })
        .catch(err => {
          reject(err)
        });
      });
    },   
    descargarImagen() {
      this.setUrl("message-template");

      this.requestApi({
        method: "POST",
        data: {
          accion: 'descargarContenido',
          contentId: this.datosPlantilla.content_id
        },
      })
      .then(res => {        
        this.datosImagen = "data:"  + res.data.detail.mime_type +  ";base64," + res.data.detail.data;
      })
      .catch(err => {
        //reject(err)
      });      
    },

    subirImagen(){
      this.overlay = true
      let formData = new FormData();
      formData.append("accion", "cargarVistaPrevia");
      //formData.append("plantilla", JSON.stringify(this.datosPlantilla));
      formData.append("tenantId", this.tenantId);
      formData.append("archivos[]", this.datosPlantilla.url_archivo);

      this.setUrl("message-template");
      axios.post(this.url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + this.token,
            },
          }).then((res) => {          
              this.overlay = false;
              this.datosImagen = res.data.detail.data
               
          }).catch((err) => {              
              this.overlay = false;
          }).finally((err) => {              
              this.overlay = false;
          });    
    }

  },
  mounted() {
    this.cargarDatos();
    this.setTitleToolbar("PLANTILLA");
  },
};
</script>
